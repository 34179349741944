import styled, {keyframes} from 'styled-components'
import theme from "../theme/theme";
import React from "react";
import { Link } from 'gatsby';

const BookingContent = styled.div`
    
    .selection-container {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto auto;
        align-items: center;
        gap: 30px;
        background-color: white;
        margin-top: 20px;
        font-family: Roboto, sans-serif;
        padding: 20px 30px;
        width: 100%;
        border-radius: 10px;
    }
    
    .image-container {
        grid-column: 1;
        grid-row: 1 / span 2;
    }
    
    .smartphone-image {
        width: 300px;
    }
    
    .choice-info, .choice-sub-container {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
    
    .choice-info {
        grid-column: 2;
        grid-row: 1;
    }
    
    .choice-sub-container {
        grid-column: 2;
        grid-row: 2;
    }
    
    .modele-title {
        font-weight: bold;
        font-size: 40px;
    }
    
    .info-label {
        font-size: 15px;
        display: flex;
        flex-direction: row;       
        flex-wrap: wrap;
    }
    
    .info-label p {
        white-space: nowrap;
        margin-right: 20px;
    }
    
    .flag-icon {
        height: 20px;
        vertical-align: middle;
        margin: 0 5px 5px 0;
    }
    
    .check-icon, .pay10-icon {
        height: 24px;
        vertical-align: middle;
        margin: 0 5px 2px 0;
    }
    
    .button-container {
        display: flex;
        flex-wrap: wrap;
    }
    
    .capacity-label, .color-label {
        font-size: 15px;
    }
    
    .capacity-button {
        width: 130px;
    }
    
    .color-button {
        height: 30px;
        min-width: 90px;
    }
    
    .grade-button {
        height: 80px;
        width: 140px;
    }

    .grade-label {
        & > :nth-child(1) { // État
            font-size: 14px;
            margin-bottom: 5px;
            font-weight: normal;
        }

        & > :nth-child(2) { // Prix
            font-size: 17px;
            margin-bottom: 5px;
            font-weight: bold;
        }

        & > :nth-child(3) { // Prix x 10
            font-size: 12px;
            font-weight: normal;
        }
    }
    
    .choice-label {
        font-weight: bold;
        font-size: 14px;
    }
    
    .info-reconditionnement {
        margin-top: 40px;
        text-align: justify;
        line-height: 20px;
    }
    
    .chez-save {
        background-color: ${theme.colors.primary};
        color: white;
        font-weight: bold;
    }
    
    .autres-reconditionnes {
        font-weight: bold;
        font-size: 20px
    }
    
    .smartphone-card-list {
        display: flex;
        gap: 20px;
        margin: 20px 0;
        justify-content: space-around;
    }
    
    .breadcrumb-item {
        text-decoration: none;
        white-space: nowrap;
        &:hover {
            text-decoration: underline;
        }
        
    }
    
    b {
        font-weight: bold;
    }
    
    @media (max-width : ${({theme}) => theme.breakpoints.md}) {
        
        .selection-container {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            padding: 10px 20px;
        }

        .choice-info {
            order: 1;
        }

        .image-container {
            order: 2;
            align-items: center;
        }

        .choice-sub-container {
            order: 3;
        }
        
        .smartphone-image {
            width: 100%;
            max-width: 300px;
        }
        
        .modele-title {
            font-size: 30px;
        }
        
        .info-label {
            flex-direction: column;
            gap: 10px;
        }

        .flag-icon {
            margin: 0 10px 5px 0;
        }
        
        .check-icon {
            margin: 0 11px 2px 3px;
        }
        
        .pay10-icon {
            margin: 0 9px 2px 1px;
        }
        
        .capacity-button {
            width: 80px;
            height: 55px;
        }
        
        .grade-button {
            width: 115px;
            min-height: 80px;
            height: auto;
        }
        
        .info-reconditionnement {
            margin: 40px 20px 0 20px;
        }
        
        .autres-reconditionnes {
            margin: 0 20px;
        }
        
        .smartphone-card-list {
            margin: 10px;
            padding: 0 10px 15px 10px;
            overflow-x: auto;
        }

        .smartphone-card-list::-webkit-scrollbar {
            height: 8px;
        }

        .smartphone-card-list::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 4px;
        }

        .smartphone-card-list::-webkit-scrollbar-thumb:hover {
            background-color: #555;
        }

        .smartphone-card-list::-webkit-scrollbar-track {
            background-color: #f0f4f8;
        }
        
        .card {
            flex: 0 0 auto;
        }
        
    }
    
`

const Title = styled.h1`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    color: ${({theme}) => theme.colors.secondary};

    @media (min-width: ${({theme}) => theme.breakpoints.md}) {
        margin-top: 30px;
        font-size: 20px;
        line-height: 24px;
    }
`

const Modal = styled.div`
    display: block;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
`

const ModalContent = styled.div`
    display: block;
    justify-content: center;
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;

    @media (max-width : ${({theme}) => theme.breakpoints.md}) {
        position: fixed;
        margin: 40px auto;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
    }
    
`

const Close = styled.span`
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;

    &:hover,
    &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
`

interface ButtonProps {
    btnSize?: 'large'
}

const Button = styled.button<ButtonProps>`
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 35px;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    -webkit-appearance: none; // fix iOS display
    transition: all 300ms ease;
    margin-top: 20px;
    
`;

const SelectionSummary = styled.span`
    display: inline-grid;
    align-items: center;
    margin: 20px;
    min-width: min-content;
    gap: 10px;
    
    & > :nth-child(1) {
        margin-top: 0;
        grid-area: image;

        & > :nth-child(1) {
            width: 100%;
            max-width: 120px;
        }
        
    }

    & > :nth-child(2) {
        grid-area: modele;
        color: ${({theme}) => theme.colors.secondary};
        font-weight: bold;
    }

    & > :nth-child(3) {
        grid-area: color;
        color: ${({theme}) => theme.colors.secondary};
        white-space: nowrap;
    }

    & > :nth-child(4) {
        grid-area: capacity;
        color: ${({theme}) => theme.colors.secondary};
        white-space: nowrap;

        & > :nth-child(1) {
            width: 17px;
            vertical-align: middle;
            margin: 0 8px 2px 0;
        }
        
    }

    & > :nth-child(5) {
        grid-area: grade;
        color: ${({theme}) => theme.colors.secondary};
    }

    & > :nth-child(6) {
        grid-area: price;
        color: ${({theme}) => theme.colors.secondary};
        font-weight: bold;
    }

    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      'image modele modele'
      'image color capacity'
      'image grade price';
    
`

const BookingSummary = styled.div`
    display: inline-grid;
    background-color: #E8E8E8;
    border-radius: 10px;
    margin-top: 30px;
    text-align: left;
    gap: 10px 20px; 
    padding: 22px;
    align-items: center;

    & > :nth-child(1) {
        margin-top: 5px;
        grid-area: image;
    }

    & > :nth-child(2) {
        margin-top: 0;
        grid-area: modele;
        font-weight: bold;
    }

    & > :nth-child(3) {
        margin-top: 0;
        grid-area: shopName;
        font-weight: bold;
        font-size: small;

        & > :nth-child(1) { // SAVE icon
            width: 15px;
            vertical-align: middle;
            margin: 0 3px 3px 0;
        }
        
    }

    & > :nth-child(4) {
        margin-top: 0;
        grid-area: shopAddress;
        text-decoration: underline;
        text-decoration-color: inherit;
        font-size: small;

        & > :nth-child(1) { // Location icon
            width: 10px;
            vertical-align: middle;
            margin: 0 6px 3px 2px;
        }
        
    }

    grid-template-columns: 1fr auto;
    grid-template-areas:
    'image  modele'
    'image  shopName'
    'image  shopAddress';
    
`

const BookingForm = styled.form`
    display: grid;
    flex-direction: column;
    padding: 22px 0;

    & > :nth-child(1) {
        margin-top: 0;
        grid-area: lastname;
    }

    & > :nth-child(2) {
        margin-top: 22px;
        grid-area: firstname;
    }

    & > :nth-child(3) {
        margin-top: 22px;
        grid-area: email;
    }

    & > :nth-child(4) {
        margin-top: 22px;
        grid-area: phone;
    }

    & > *[class^='Form__Options'] {
        margin-top: 22px;
        grid-area: options;
    }

    & > :nth-child(5) {
        margin-top: 22px;
        grid-area: labelAsterix;
    }

    & > :last-child {
        align-items: center;
        margin: 22px 0;
        grid-area: submit;
    }

    grid-template-columns: 1fr;
    grid-template-areas:
    'lastname'
    'firstname'
    'email'
    'phone'
    'options'
    'labelAsterix'
    'submit';

    @media (min-width: ${({theme}) => theme.breakpoints.md}) {
        column-gap: 88px;
        padding: 44px;

        & > :nth-child(1) {
            margin-top: 0;
            grid-area: lastname;
        }

        & > :nth-child(2) {
            margin-top: 0;
            grid-area: firstname;
        }

        & > :nth-child(3) {
            margin-top: 44px;
            grid-area: email;
        }

        & > :nth-child(4) {
            margin-top: 44px;
            grid-area: phone;
        }

        & > :nth-child(5) {
            margin-top: 22px;
            grid-area: labelAsterix;
        }

        & > :last-child {
            margin: 22px 0;
            grid-area: submit;
        }

        & > *[class^='Form__Options-fvzRrE'] {
            margin-top: 22px;
            grid-area: options;
        }

        grid-template-columns: 1fr 1fr;
        grid-template-areas:
      'lastname firstname'
      'email    phone'
      'options  options'
      'labelAsterix labelAsterix' 
      'submit submit';
    }
`

interface SelectionButtonProps {
    selected: boolean;
    width?: string;
    height?: string;
}

const SelectionButton = styled.button<SelectionButtonProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 15px 5px 0;
    padding: 10px;
    width: ${props => (props.width ? props.width : "length")};
    height: ${props => (props.height ? props.height : "length")};
    background-color: ${props => (props.selected ? theme.colors.secondary : 'inherit')};
    color: ${props => (props.selected ? "white" : 'inherit')};
    font-weight: ${props => (props.selected ? "bold" : "normal")};
    border: ${props => (props.selected ? "none" : "1px solid gray")};
    border-radius: 15px;
    cursor: pointer;
`

const BookingButton = styled.button`
    display: flex;
    width: 400px;
    height: 60px;
    align-items: center;
    background-color: ${theme.colors.secondary};
    color: white;
    padding: 10px 0 10px 20px;
    border-radius: 15px;
    border: none;
    font-size: 22px;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    cursor: pointer;
    white-space: nowrap;
    
    & > :nth-child(1){
        margin-right: 20px;
    }

    @media (max-width : ${({theme}) => theme.breakpoints.md}) {
        width: auto;
        font-size: 18px;
        white-space: normal;

        & > :nth-child(1){
            width: 20px;
            margin-right: 10px;
        }
        
    }


`

const BookingContainer = styled.span`
    display: inline-grid;
    align-items: center;
    border: 1px solid ${theme.colors.secondary};
    border-radius: 10px;
    margin-top: 20px;
    text-align: left;
    column-gap: 0;
    padding: 20px;

    & > :nth-child(1) {
        margin-top: 0;
        margin-right: 20px;
        grid-area: image;
        width: 50px;
    }

    & > :nth-child(2) {
        margin-top: 0;
        grid-area: modele;
        font-weight: bold;
    }

    & > :nth-child(3) {
        & > :nth-child(1) { // SAVE icon
            width: 15px;
            vertical-align: middle;
            margin: 0 3px 3px 0;
        }
        margin-top: 10px;
        grid-area: shopName;
        font-weight: bold;
        font-size: small;
    }

    & > :nth-child(4) {
        & > :nth-child(1) { // Location icon
            width: 10px;
            vertical-align: middle;
            margin: 0 6px 3px 2px;
        }
        margin-top: 10px;
        grid-area: shopAddress;
        text-decoration: underline;
        text-decoration-color: inherit;
        font-size: small;
    }

    & > :nth-child(5) {
        margin-top: 10px;
        grid-area: button;
    }

    grid-template-columns: auto 1fr;
    grid-template-areas:
    'image  modele'
    'image  shopName'
    'image  shopAddress'
    'button  button';
    
`

interface CircleProps {
    color: string;
}

const Circle = styled.span<CircleProps>`
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid lightgray;
    background-color: ${props => props.color};
    margin: 0 8px 2px 0;
`

const rewrite = (text: string) => {
    switch (text) {
        case "WHITE":
            return "Blanc";
        case "RED":
            return "Rouge";
        case "BLACK":
            return "Noir";
        case "GOLD":
            return "Or";
        case "GREY":
            return "Gris";
        case "BLUE":
            return "Bleu";
        case "YELLOW":
            return "Jaune";
        case "GREEN":
            return "Vert";
        case "SILVER":
            return "Argent";
        case "PURPLE":
            return "Violet";
        case "PINK":
            return "Rose";
        case "BROWN":
            return "Marron";
        case "ORANGE":
            return "Orange";
        case "A":
            return "Parfait état";
        case "B":
            return "Très bon état";
        case "C":
            return "État correct";
        default:
            return text;
    }
}

type BreadcrumbItem = {
    label: string;
    href?: string;
};

type BreadcrumbProps = {
    items: BreadcrumbItem[];
};

const BreadcrumbComponents = {
    Nav: styled.nav`
        font-family: Roboto, sans-serif;
    `,
    Ol: styled.ol`
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0;
        @media (max-width: ${({theme}) => theme.breakpoints.md}) {
            margin: 10px 20px;
        }
    `,
    Li: styled.li`
        margin-right: 0.5rem;

        &:after {
            content: '/';
            margin-left: 0.5rem;
        }

        &:last-child:after {
            content: '';
        }
    `,
    ActiveSpan: styled.span`
        color: ${theme.colors.secondary};
    `,
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
    return (
        <BreadcrumbComponents.Nav>
            <BreadcrumbComponents.Ol>
                {items.map((item, index) => (
                    <BreadcrumbComponents.Li key={index} aria-current={index === items.length - 1 ? 'page' : undefined}>
                        {index !== items.length - 1 ? (
                            <Link to={`${item.href}`} className="breadcrumb-item">{item.label}</Link>
                        ) : (
                            <BreadcrumbComponents.ActiveSpan>{item.label}</BreadcrumbComponents.ActiveSpan>
                        )}
                    </BreadcrumbComponents.Li>
                ))}
            </BreadcrumbComponents.Ol>
        </BreadcrumbComponents.Nav>
    );
};

export interface Step {
    number: number;
    title: string;
    description: string;
    subDescription?: string[];
}

// Component to display a single step
const StepItem: React.FC<{ step: Step }> = ({ step }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', margin: '30px 20px' }}>
            <div style={{ fontSize: '50px', fontWeight: 'bold', textShadow: `${theme.colors.primary} 2px 2px`, color: theme.colors.secondary, marginRight:"20px" }}>{step.number}</div>
            <div><b style={{fontWeight: "bold"}}>{step.title}</b>{step.description}
                {step.subDescription ? (
                    <ul>
                        {step.subDescription.map((desc, index) => (
                            <li key={index} style={{display: "flex", flexDirection: "row"}}>
                                <span style={{margin: "0 8px"}}>
                                    •
                                </span>
                                <span style={{}}>
                                    {desc}
                                </span>
                            </li>
                        ))}
                    </ul>) : null}
            </div>
        </div>
    );
};

const StepList: React.FC<{ steps: Step[] }> = ({ steps }) => {
    return (
        <div style={{backgroundColor:"white", marginTop:"20px", textAlign:"justify", padding:"10px 20px", fontFamily: 'Roboto, sans-serif', lineHeight: "20px"}}>
            {steps.map((step) => (
                <StepItem key={step.number} step={step} />

            ))}
        </div>
    );
};

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: ${spin} 1s ease infinite;
    align-self: center;
`;

const SpinnerContainer = styled.div`
    align-self: center;
    vertical-align: middle;
    height: 500px;
    justify-content: center;
    width: 100%;
    display: flex;

`

export default Object.assign(BookingContent, {
    BookingForm,
    Modal,
    ModalContent,
    Close,
    Title,
    SelectionSummary,
    BookingSummary,
    Button,
    SelectionButton,
    rewrite,
    Circle,
    Breadcrumb,
    BookingContainer,
    BookingButton,
    StepList,
    Spinner,
    SpinnerContainer,
})